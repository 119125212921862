.logo {
  text-align: center;
  padding: 14px 15px;
  display: block;
}
.hidden-scroll {
  &::-webkit-scrollbar {
    display: none;
  }
}
.avatar-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
  background-color: #fff;
}
.user-name {
  margin-top: 10px;
}
