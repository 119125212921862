.order-bags {
  background-color: rgb(204 204 204);
  background-color: rgb(244 244 244);
  height: 100vh;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  .header {
    height: 65px;
  }
  .content-scroll {
    overflow: auto;
    height: calc(100vh - 65px - 50px);
  }

  .footer {
    height: 50px;
  }

  .modal-scan {
    background-color: white;
    z-index: 9;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}
