/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

// ::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//   border-radius: 4px;
//   background-color: white;
// }

// ::-webkit-scrollbar {
//   width: 6px;
//   height: 6px;
//   background-color: #f5f5f5;
// }

// ::-webkit-scrollbar-thumb {
//   border-radius: 4px;
//   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//   background-color: #ccc7c7;
// }

.marker-position {
  bottom: 6px;
  position: relative;
}
