.ant-modal-confirm .ant-modal-confirm-btns {
  text-align: center !important;
}
.ant-card-bordered {
  border: 1px solid rgb(0 0 0 / 32%) !important;
}
.ant-card-head {
  border-bottom: 1px solid rgb(0 0 0 / 32%) !important;
}

.shadow-lg {
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 10%),
    -1px -2px 6px -2px rgb(0 0 0 / 5%) !important;
}

.ant-steps-item-title {
  line-height: 15px !important;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgb(0 0 0 / 79%) !important;
}
