.loading-container {
  position: fixed;
  left: 0;
  top: 2px;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0.8;
  z-index: 9999;
  align-items: center;
  text-align: center;
  justify-content: center;
}
