.product-drawer {
  .ant-drawer-content {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .close-product {
    position: absolute;
    right: 20px;
    top: 8px;
    z-index: 999;
  }
}
