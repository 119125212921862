:root {
  --header-height: 64px;
  --primary-color: #3180f6;
  --primary-color-1: #e6f7ff;

  --primary-text-color: #4a5568;
  --secondary-text-color: #3180f6;
  --bg-white: #ffffff;
  --bg-layout: #f7f8fb;
  --bg-main: rgba(8, 19, 88, 0.9);
  --bg-success-color: #48bb78;
  --bg-danger-color: #d61c15;
  --border-color: rgba(0, 0, 0, 0.06);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.text-primary {
  color: var(--primary-text-color) !important;
}

.text-primary-1 {
  color: var(--primary-color-1) !important;
}

.text-secondary {
  color: var(--secondary-text-color) !important;
}

.bg-success {
  background-color: var(--bg-success-color) !important;
}
.bg-danger {
  background-color: var(--bg-danger-color) !important;
}

.w-100 {
  width: 100% !important;
}
